import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle";
import { XPerGroupForYCampaign } from "../../models/campaigns/Campaign";
import _ from "lodash";

export default function calculateXPerGroupForYCampaign(campaign: XPerGroupForYCampaign, orderArticles: OrderArticle[]) {
  let discountAmount = 0;

  const sortedOrderArticles = _.cloneDeep(orderArticles).sort(
    (a, b) =>
      getTotalPrice(a, { count: 1, includeOptions: false, includeExtraOrderArticles: false }) -
      getTotalPrice(b, { count: 1, includeOptions: false, includeExtraOrderArticles: false })
  );

  const orderArticlesPerGroup = campaign.x_per_group_for_y.map((group) => {
    const productIdsMap = _.chain(group.productIds)
      .map((id) => [id, true])
      .fromPairs()
      .value();

    return sortedOrderArticles.filter((orderArticle) => productIdsMap[orderArticle.article.id]);
  });

  const totalOrderArticlesCountPerGroup = orderArticlesPerGroup.map((orderArticles) =>
    orderArticles.reduce((sum, orderArticle) => {
      return sum + orderArticle.count;
    }, 0)
  );

  const numberOfOccurrencesPerGroup = totalOrderArticlesCountPerGroup.map((count, index) =>
    Math.floor(count / campaign.x_per_group_for_y[index].numberOfProducts)
  );

  const numberOfOccurrences = Math.min(...numberOfOccurrencesPerGroup);

  for (let i = 0; i < numberOfOccurrences; i++) {
    const amountsPerGroup = orderArticlesPerGroup.map((orderArticles, groupIndex) => {
      let amount = 0;
      let itemsLeft = campaign.x_per_group_for_y[groupIndex].numberOfProducts;
      orderArticles.some((orderArticle) => {
        if (orderArticle.count === 0) {
          return false;
        }
        const count = Math.min(orderArticle.count, itemsLeft);
        amount += getTotalPrice(orderArticle, { count, includeOptions: false, includeExtraOrderArticles: false });
        itemsLeft -= count;
        orderArticle.count -= count;
        return itemsLeft === 0;
      });

      return amount;
    });

    const sum = _.sum(amountsPerGroup);
    discountAmount += sum - (campaign.Xs?.[0] ?? sum);
  }

  return discountAmount;
}
