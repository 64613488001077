import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle";
import Campaign from "../../models/campaigns/Campaign";

export default function calculateBuyXPayYCampaign(campaign: Campaign, orderArticles: OrderArticle[]) {
  let discountAmount = 0;

  if (campaign.Xs?.length === 2) {
    orderArticles = orderArticles.sort((a, b) => getTotalPrice(a, { count: 1 }) - getTotalPrice(b, { count: 1 }));
    const totalCount = orderArticles.reduce((sum, orderArticle) => {
      return sum + orderArticle.count;
    }, 0);
    const occurrences = Math.floor(totalCount / campaign.Xs[0]);

    const numberOfFreeItems = occurrences * (campaign.Xs[0] - campaign.Xs[1]);

    let numberOfItemsDiscounted = 0;
    orderArticles.some((orderArticle) => {
      if (numberOfItemsDiscounted + orderArticle.count <= numberOfFreeItems) {
        discountAmount += getTotalPrice(orderArticle);
        numberOfItemsDiscounted += orderArticle.count;
        return false;
      } else {
        const count = numberOfFreeItems - numberOfItemsDiscounted;
        discountAmount += getTotalPrice(orderArticle, { count });
        numberOfItemsDiscounted += count;
        return true;
      }
    });
  }
  return discountAmount;
}
