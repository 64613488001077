import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store.tsx";
import _ from "lodash";
import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle.ts";
import { VoucherDefV2, VoucherV2 } from "../vouchersV2Slice.tsx";
import { selectVouchersV2 } from "./selectVouchersV2.tsx";
import { ArticleType } from "../../models/menu/Article.ts";

const sorting: { [key in VoucherDefV2["discountType"]]: number } = {
  COLLECTION: 0,
  ADD_DISCOUNTED_PRODUCT: 1,
  PERCENTAGE_OFF_PRODUCT: 2,
  AMOUNT_OFF_PRODUCT: 3,
  PERCENTAGE_OFF_TOTAL: 4,
  AMOUNT_OFF_TOTAL: 5,
};

export const selectVoucherDiscounts = createSelector(
  [selectVouchersV2, (state: RootState) => state.shoppingCart.items],
  (vouchers, orderArticles) => {
    let discounts: {
      [orderArticleUuid: string]: {
        discount: number;
      };
    } = {};

    discounts = _.chain(orderArticles)
      .keyBy("uuid")
      .mapValues(() => ({ discount: 0 }))
      .value();

    [...vouchers]
      .sort((a, b) => sorting[a.voucherdef.discountType] - sorting[b.voucherdef.discountType])
      .filter((voucher) => minBasketPriceValidation(voucher, orderArticles))
      .forEach((voucher) => {
        if (voucher.voucherdef.discountType == "PERCENTAGE_OFF_TOTAL") {
          const discountPercentage = voucher.voucherdef.priceDiscountPercentage;
          orderArticles.forEach((orderArticle) => {
            discounts[orderArticle.uuid].discount +=
              (Math.round(
                (((getTotalPrice(orderArticle, { count: 1, includeArticleTypes: { [ArticleType.Regular]: true } }) -
                  discounts[orderArticle.uuid].discount / orderArticle.count) *
                  discountPercentage) /
                  100 -
                  Number.EPSILON) *
                  100
              ) /
                100) *
              orderArticle.count;
          });
        } else if (voucher.voucherdef.discountType == "PERCENTAGE_OFF_PRODUCT") {
          const priceDiscountPercentage = Number(voucher.voucherdef.priceDiscountPercentage);
          let maxChooseItems = voucher.voucherdef.maxChooseItems;
          const voucherdef = voucher.voucherdef;
          const productIdsMap = _.keyBy(voucher.voucherdef.includedProducts_JSON);
          orderArticles
            .filter((orderArticle) => productIdsMap[orderArticle.article.id])
            .sort((a, b) => getTotalPrice(b, { count: 1 }) - getTotalPrice(a, { count: 1 }))
            .some((orderArticle) => {
              const toDeduct =
                Math.round(
                  (((getTotalPrice(orderArticle, { count: 1 }) -
                    discounts[orderArticle.uuid].discount / orderArticle.count) *
                    priceDiscountPercentage) /
                    100 -
                    Number.EPSILON) *
                    100
                ) / 100;
              for (let i = 0; i < orderArticle.count; i++) {
                discounts[orderArticle.uuid].discount += toDeduct;
                if (maxChooseItems > 0) {
                  maxChooseItems--;
                }
                if (voucherdef.maxChooseItems > 0 && maxChooseItems === 0) {
                  return true;
                }
              }
              return voucherdef.maxChooseItems > 0 && maxChooseItems === 0;
            });
        } else if (voucher.voucherdef.discountType == "AMOUNT_OFF_TOTAL") {
          let totalDiscountAmount = voucher.voucherdef.priceDiscountAmount;
          orderArticles.some((orderArticle) => {
            const toDeduct = Math.min(
              getTotalPrice(orderArticle) - discounts[orderArticle.uuid].discount,
              totalDiscountAmount
            );
            discounts[orderArticle.uuid].discount += toDeduct;
            totalDiscountAmount -= toDeduct;
            return totalDiscountAmount <= 0;
          });
        } else if (voucher.voucherdef.discountType == "AMOUNT_OFF_PRODUCT") {
          const totalDiscountAmount = Number(voucher.voucherdef.priceDiscountAmount);
          let maxChooseItems = voucher.voucherdef.maxChooseItems;
          const voucherdef = voucher.voucherdef;
          const productIdsMap = _.keyBy(voucher.voucherdef.includedProducts_JSON);
          orderArticles
            .filter((orderArticle) => productIdsMap[orderArticle.article.id])
            .sort((a, b) => getTotalPrice(b, { count: 1 }) - getTotalPrice(a, { count: 1 }))
            .some((orderArticle) => {
              const toDeduct =
                Math.round(
                  (Math.min(
                    getTotalPrice(orderArticle, { count: 1 }) -
                      discounts[orderArticle.uuid].discount / orderArticle.count,
                    totalDiscountAmount
                  ) -
                    Number.EPSILON) *
                    100
                ) / 100;

              for (let i = 0; i < orderArticle.count; i++) {
                discounts[orderArticle.uuid].discount += toDeduct;
                if (maxChooseItems > 0) {
                  maxChooseItems--;
                }
                if (voucherdef.maxChooseItems > 0 && maxChooseItems === 0) {
                  return true;
                }
              }
              return voucherdef.maxChooseItems > 0 && maxChooseItems === 0;
            });
        }
      });

    return { discountsPerOrderArticle: discounts };
  }
);

function minBasketPriceValidation(voucher: VoucherV2, orderArticles: OrderArticle[]): boolean {
  if (voucher.voucherdef.minBasketPrice != null && voucher.voucherdef.minBasketPrice > 0) {
    return _.sumBy(orderArticles, (orderArticle) => getTotalPrice(orderArticle)) >= voucher.voucherdef.minBasketPrice;
  } else {
    return true;
  }
}
